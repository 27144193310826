<template>
  <div class="detailBox">
    <NavBar :title="$route.query.title"></NavBar>
    <Space :height="30"></Space>
    <div class="contentBox">
      <div class="content">
        <TextBox color="title" :font-size="30">{{ title }}</TextBox>
        <TextBox color="intro" :font-size="22">{{ time }}</TextBox>
        <Space :height="30"></Space>
        <div v-html="content"></div>
      </div>
    </div>
    <Space :height="30"></Space>
  </div>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: [],
  data() {
    return {
      title: '',
      time: '',
      content: '',
    };
  },
  // created () {},
  mounted() {
    this.$store.commit('setBodyColor');
    this.getContent();
  },
  methods: {
    getContent() {
      this.$http('get', this.$route.query.url).then(res => {
        this.title = res.data.title;
        this.time = res.data.createTime;
        this.content = res.data.detail;
      });
    },
  },
  // watch: {},
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.content {
  width: 690px;
  background: @white;
  border-radius: 10px;
  padding: 40px 56px;
  line-height: 1.6;
  font-size: 26px;
  color: #70708c;
}
</style>
